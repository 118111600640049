import { useState } from 'react';

import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';

import useToken from '../../hooks/useToken';
import useComments from '../../hooks/useComments';
import useForums from '../../hooks/useForums';

const Comments = ({ ago, forum }) => {
  const { API_URL } = window;

  const [page, setPage] = useState(0);

  const { isLoading, comments } = useComments(forum);
  const { token } = useToken({ redirect: false });
  const { forums } = useForums();

  const currentForum = Object.values(forums).find(({ z }) => z === forum);

  const {
    color: backgroundColor,
    name: title,
    imageURL,
    z
  } = currentForum || {};

  const onClickUpvote = id => async () => {
    const response = await fetch(`${API_URL}/upvote`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token,
        itemId: id
      })
    });

    if (response?.ok) {
      const { success } = await response.json();

      if (success) {
        window.location.reload();
      }
    }
  };

  const onClickMore = () => {
    setPage(Math.min(9999, page + 1));
  };

  const byDate = (a, b) => new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1;

  const commentData = Object
    .values(comments)
    .sort(byDate)
    .slice(0, (page * 24) + 24)

  return !isLoading && <>
    <Navigation
      forum={forum}
      backgroundColor={backgroundColor}
      imageURL={imageURL}
      title={title}
    />
    {commentData.length === 0 && <em style={{ opacity: .5 }}>No comments.</em>}
    <ol className="feed no-list">
      {commentData.map(({
        id,
        parentId,
        topic: topicText,
        text,
        url: topicUrl,
        points,
        createdAt
      }) => {
        const nested1 = comments[parentId];
        const nested2 = nested1 && comments[comments[parentId]?.parentId];
        const nested3 = nested2 && comments[comments[comments[parentId]?.parentId]?.parentId];
        const nested4 = nested3 && comments[comments[comments[comments[parentId]?.parentId]?.parentId]?.parentId];

        const rootId = nested1?.parentId
          ? nested2?.parentId
            ? nested3?.parentId
              ? nested4?.parentId
                ? nested4.parentId
                : nested3.parentId
              : nested2.parentId
            : nested1.parentId
          : parentId;

        const commentsUrl = `/z/${z}/item?id=${rootId}`;

        return (
          <li className="topic" key={id}>
            <span>
              <span className="vote">
                <span className="upvote" onClick={onClickUpvote(id)}>▲</span>
              </span>
              <span className="author">
                <span className="info">
                  {topicText}
                  &nbsp;<span>-</span>&nbsp;
                  <em>
                    {ago(new Date(createdAt))}
                  </em>
                </span>
              </span>
            </span>
            <span className="small">
              <em>{points} {`point${Math.abs(points) !== 1 ? 's' : ''}`}</em>
            </span>
            <p className="message preview">
              <a
                href={commentsUrl}
                target={topicUrl ? '_blank' : '_self'}
                rel="noreferrer"
                className="item preview"
              >
                <span dangerouslySetInnerHTML={{ __html: text }} />
              </a>
            </p>
          </li>
        );
      })}
    </ol>
    {Object.values(comments).length > commentData.length && <button onClick={onClickMore}>More</button>}
    <Footer />
  </>;
};

export default Comments;
