import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import Auth from './pages/Auth';
import Comments from './pages/Comments';
import FAQ from './pages/FAQ';
import Home from './pages/Home';
import Pricing from './pages/Pricing';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Submit from './pages/Submit';
import Topic from './pages/Topic';
import Topics from './pages/Topics';
import ZSlash from './pages/ZSlash';

import './App.css';

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo('en-US');

const ago = date => timeAgo.format(date);

const App = () => {
  const params = new URL(document.location).searchParams;
  const id = params.get('id');

  const { pathname } = window.location;

  // eslint-disable-next-line no-unused-vars
  const [z, forum = 'news'] = pathname.split('/').filter(Boolean);

  let component = '404';

  switch (pathname) {
    case '/':
      component = <Home ago={ago} />;

      break;

    case '/z':
    case '/z/':
      component = <ZSlash ago={ago} />;

      break;

    case `/z/${forum}`:
    case `/z/${forum}/`:
      component = <Topics ago={ago} forum={forum} />;

      break;

    case `/z/${forum}/newest`:
    case `/z/${forum}/newest/`:
      component = <Topics ago={ago} forum={forum} sortBy="date" />;

      break;

    case `/z/${forum}/newcomments`:
    case `/z/${forum}/newcomments/`:
      component = <Comments ago={ago} forum={forum} />;

      break;

    case `/z/${forum}/item`:
    case `/z/${forum}/item/`:
      component = <Topic ago={ago} forum={forum} id={id} />;

      break;

    case `/z/${forum}/submit`:
    case `/z/${forum}/submit/`:
      component = <Submit forum={forum} />;

      break;

    case '/login':
    case '/login/':
    case '/signup':
    case '/signup/':
    case `/z/${forum}/login`:
    case `/z/${forum}/login/`:
      component = <Auth forum={forum} />;

      break;

    case '/pricing':
    case '/pricing/':
      component = <Pricing />;

      break;

    case '/faq':
    case '/faq/':
      component = <FAQ />;

      break;

    case `/z/${forum}/settings`:
    case `/z/${forum}/settings/`:
      component = <Settings forum={forum} />;

      break;

    case `/z/${forum}/user`:
    case `/z/${forum}/user/`:
      component = <Profile id={id} ago={ago} forum={forum} />;

      break;

    default:
      break;
  }

  return (
    <div id="news">
      {component}
    </div>
  )
};

export default App;
