import Header from '../../components/Header';

const FAQ = () => <>
  <Header />
  <main className="landing">
    <div className="page">
      <section>
        <h3>FAQ</h3>
        <h4>Some answers to frequently asked questions.</h4>
        <div className="faq">
          <strong>Q: How do I find existing communities?</strong>
          <p>A: Go to <a href="/z">zSlash</a>.</p>
        </div>
        <div className="faq">
          <strong>Q: What is zSlash?</strong>
          <p>A: <a href="/z">zSlash</a> is the free hosted solution we offer. Creating your own zSlash community (e.g. <code>z/gardening</code>) is simple to setup and allows your forum to be easily discovered on our platform. For more control, you can host the forum yourself using the zDiff Web SDK and integrate it into your existing website or application.</p>
        </div>
        <div className="faq">
          <strong>Q: How much?</strong>
          <p>A: Free for the most recent 1000 posts &mdash; or $10/month for up to 1 million. See <a className="underline" href="/pricing">pricing</a>.</p>
        </div>
        <div className="faq">
          <strong>Q: Can I host a forum on my own domain?</strong>
          <p>A: If you have a Pro account, you can access the zDiff Web API from your HTTP capable language of choice, or use the zDiff Web SDK to integrate React components into your application. See <a className="underline" href="/pricing">pricing</a> for all features.</p>
        </div>
        <div className="faq">
          <strong>Q: Can you host my forum, but point my domain to it?</strong>
          <p>A: Working on it. It will be a Pro feature.</p>
        </div>
        <div className="faq">
          <strong>Q: Are there moderation features?</strong>
          <p>A: If you created a forum and have a Pro account, you can appoint moderators and configure per-user automatic promotion/demotion of content (including forum-wide banning). See <a className="underline" href="/pricing">pricing</a> for all features.</p>
        </div>
        <div className="faq">
          <strong>Q: How do I access forum settings?</strong>
          <p>A: If you are the moderator, add <code>/settings</code> to the end of a zSlash URL (e.g. <code>z/gardening/settings</code>).</p>
        </div>
        <div className="faq">
          <strong>Q: What happens when a user is banned?</strong>
          <p>A: When banned, content-related HTTP requests coming from the user's machine will receive 403 (Forbidden) responses and their content will not be posted.</p>
          <p>Bans apply to the following requests: New topics, new comments, upvotes, downvotes, and editing of topics and comments. Banned users can still login and logout, edit their profile, create forums, and perform normal actions on other forums.</p>
          <p>A moderator cannot ban themselves nor appoint a banned user as moderator (without unbanning them). There are two kinds of bans: 1) Forum-wide bans that moderators can use to silence accounts on forums they moderate 2) Site-wide bans &mdash; currently an empty list, reserved for genuine spam &mdash; where the user cannot perform aforementioned actions on any forum.</p>
        </div>
      </section>
    </div>
  </main>
</>;

export default FAQ;
