import { useEffect, useState } from 'react';
import CardList from '../../components/CardList';
import Header from '../../components/Header';
import useForums from '../../hooks/useForums';

const Home = ({ ago }) => {
  const { isLoading, forums } = useForums();
  const [top, setTop] = useState([]);

  useEffect(() => {
    if (forums?.length) {
      setTop(forums.slice(0, 7));
    }
  }, [forums]);

  return <>
    <Header />
    <main className="landing">
      <div className="page">
        <section>
          <h3>Give Your Community a Voice</h3>
          <h4>Launch an upvote/downvote style message board or link aggregator community for free.</h4>
          <h4>Host meaningful conversations to grow, gauge, and engage your audience.</h4>
          <img
            src="/img/product.png"
            alt="zDifferentiator"
            width={1215}
            height={777}
            style={{
              width: '100%',
              height: '100%'
            }}
          />
          <div className="cta">
            <p><strong>Free forever</strong> for the latest 1000 posts &mdash; or <a className="underline" href="/pricing">$10/month</a> for up to 1 million.</p>
            <p>No credit card needed to get started. Here's a <a target="_blank" rel="noreferrer" className="underline" href="https://news.zdifferentiator.com">live demo</a>.</p>
            <div>
              <a href="/z" className="cta-button">
                <span>Get Started</span>
              </a>
            </div>
          </div>
        </section>
        {!isLoading && <section>
          {top.length === 0
            ? <em style={{ color: '#64748b' }}>No data.</em>
            : <CardList
                truncated
                title="Recent"
                items={top}
                ago={ago}
              />}
        </section>}
      </div>
    </main>
  </>;
};

export default Home;
