import { useState } from 'react';

import CardList from '../../components/CardList';
import Header from '../../components/Header';
import useForums from '../../hooks/useForums';
import useToken from '../../hooks/useToken';

const DEFAULT_COLOR = '#fff722';

const ZSlash = ({ ago }) => {
  const { API_URL } = window;
  const { isLoading, forums } = useForums();

  const [forum, setForum] = useState('');
  const [slug, setSlug] = useState('');
  const [logo, setLogo] = useState('');
  const [description, setDescription] = useState('');
  const [color, setColor] = useState(DEFAULT_COLOR);
  const [disableAnimation, setDisableAnimation] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const { token, isLoading: isAuthLoading } = useToken({
    redirect: false
  });

  const onChangeForum = ({ target: { value }}) => {
    setForum(value);

    setSlug(value
      .replace(/^\s+|\s+$/g, '')
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
    );
  };

  const onChangeSlug = ({ target: { value }}) => (
    setSlug(value)
  );

  const onChangeDescription = ({ target: { value }}) => (
    setDescription(value)
  );

  const onChangeLogo = ({ target: { value }}) => (
    setLogo(value)
  );

  const onChangeColor = ({ target: { value }}) => (
    setColor(value)
  );

  const onChangeDisableAnimation = ({ target: { checked }}) => setDisableAnimation(checked);

  const onClickCreate = async () => {
    if (!(/[a-zA-Z0-9_-]{1,100}$/).test(forum)) {
      alert('Invalid forum name.');

      return;
    }

    if (!(/^[a-z0-9]{1,100}|([a-z0-9]+(?:-[a-z0-9]+){1,100})*$/).test(slug)) {
      alert('Invalid slug.');

      return;
    }

    setIsCreating(true);

    const response = await fetch(`${API_URL}/forum`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token,
        forum,
        description,
        color,
        animationDisabled: disableAnimation,
        imageURL: logo,
        z: slug
      })
    });

    if (response?.ok) {
      const { success } = await response.json();

      setIsCreating(false);

      if (success) {
        window.location.href = `/z/${slug}`;
      }
    } else {
      if (response.status === 401) {
        localStorage.clear();

        window.location.href = '/login';

        return;
      }

      if (response.status === 403) {
        alert('Not allowed (slug might already exist).');
      }

      setIsCreating(false);
    }
  };

  return <>
    <Header />
    <main className="landing">
      <div className="page">
        <section>
          <h3>zSlash</h3>
          <h4>Find or create your community.</h4>
          {!isAuthLoading && !token && <div>
            <a href="/login" className="cta-button">
              <span>Login to Create</span>
            </a>
          </div>}
          {token && <div className="form page">
            <section>
              <h2>Create a new message board.</h2>
              <input
                placeholder="forum name"
                onChange={onChangeForum}
                value={forum}
              />
              <input
                placeholder="slug"
                onChange={onChangeSlug}
                value={slug}
              />
              <input
                placeholder="description"
                onChange={onChangeDescription}
                value={description}
              />
              <input
                placeholder="logo URL (square shape is ideal)"
                onChange={onChangeLogo}
                value={logo}
              />
              <h2>Design</h2>
              <input
                placeholder="navbar color"
                onChange={onChangeColor}
                value={color}
              />
              <div className="controls full">
                <p>Disable colorful text animation:</p>
                <input
                  type="checkbox"
                  onChange={onChangeDisableAnimation}
                  value={disableAnimation}
                  defaultChecked={disableAnimation}
                />
              </div>
              <button
                disabled={isCreating}
                onClick={onClickCreate}
              >
                Create
              </button>
            </section>
          </div>}
        </section>
        {!isLoading && <section>
          {forums.length === 0
            ? <em style={{ color: '#64748b' }}>No data.</em>
            : <CardList
                title="All Communities"
                items={forums}
                ago={ago}
              />}
        </section>}
      </div>
    </main>
  </>
};

export default ZSlash;
