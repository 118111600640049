import Card from '../Card';

import './styles.css';

const CardList = ({
  title = '',
  items = [],
  truncated = false,
  ago = () => {},
}) => (
  <>
    <h3>{title}</h3>
    <ul className="card-list">
      {items.length > 0 && items.slice(0, truncated ? 5 : items.length).map(({
        name,
        z,
        description,
        color,
        imageURL,
        updatedAt
      }) => (
        <Card key={name} {...{
          name,
          z,
          description,
          color,
          imageURL,
          ago,
          updatedAt
        }} />
      ))}
      {truncated && <li>
        <a href="/z" className="cta-button">
          View All / Create
        </a>
      </li>}
    </ul>
  </>
);

export default CardList;
