import { useEffect, useState } from 'react';

import Header from '../../components/Header';
import useForums from '../../hooks/useForums';
import useToken from '../../hooks/useToken';

const DEFAULT_COLOR = '#fff722';

const Settings = ({ forum }) => {
  const { API_URL } = window;
  const { isLoading, forums } = useForums();

  const [forumName, setForumName] = useState('');
  const [logo, setLogo] = useState('');
  const [description, setDescription] = useState('');
  const [color, setColor] = useState(DEFAULT_COLOR);
  const [disableAnimation, setDisableAnimation] = useState(false);
  const [admin, setAdmin] = useState();
  const [weights, setWeights] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const { token, user } = useToken();

  useEffect(() => {
    if (token && forum && !isLoading) {
      const details = Object.values(forums).find(({ z }) => z === forum);

      if (!details || details.admin !== user?.id) {
        window.location.href = '/z';
      }

      setForumName(details.name);
      setLogo(details.imageURL);
      setDescription(details.description);
      setColor(details.color);
      setDisableAnimation(details.animationDisabled);
      setAdmin(details.admin);
      setWeights(details.weights);
    }
  }, [isLoading, forums, forum, token, user]);

  const onChangeForumName = ({ target: { value }}) => (
    setForumName(value)
  );

  const onChangeDescription = ({ target: { value }}) => (
    setDescription(value)
  );

  const onChangeLogo = ({ target: { value }}) => (
    setLogo(value)
  );

  const onChangeColor = ({ target: { value }}) => (
    setColor(value)
  );

  const onChangeAdmin = ({ target: { value }}) => (
    setAdmin(value)
  );

  const onChangeWeight = user => ({ target: { value }}) => (
    setWeights({
      ...weights,

      [user]: value
    })
  );

  const onChangeDisableAnimation = ({ target: { checked }}) => setDisableAnimation(checked);

  const onClickUpdate = async () => {
    if (!(/[a-zA-Z0-9_-]{1,100}$/).test(forumName)) {
      alert('Invalid forum name.');

      return;
    }

    setIsEditing(true);

    const response = await fetch(`${API_URL}/settings`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token,
        forum: forumName,
        description: description || forum.description,
        color: color || forum.color,
        imageURL: logo || forum.imageURL,
        animationDisabled: disableAnimation,
        admin,
        weights,
        z: forum
      })
    });

    if (response?.ok) {
      const { success } = await response.json();

      setIsEditing(false);

      if (success) {
        window.location.href = `/z/${forum}`;
      }
    } else {
      if (response.status === 401) {
        localStorage.clear();

        window.location.href = '/login';

        return;
      }

      if (response.status === 403) {
        alert('Not allowed.');
      }

      setIsEditing(false);
    }
  };

  return <>
    <Header />
    <main className="landing">
      <div className="page">
        <section>
          <h3>zSlash</h3>
          <h4>Update settings for z/{forum}.</h4>
          {token && <div className="form page">
            <section>
              <h2>Basic information</h2>
              <input
                placeholder="forum name"
                onChange={onChangeForumName}
                value={forumName}
              />
              <input
                placeholder="description"
                onChange={onChangeDescription}
                value={description}
              />
              <input
                placeholder="logo URL (square shape is ideal)"
                onChange={onChangeLogo}
                value={logo}
              />
              <h2>Design</h2>
              <input
                placeholder="navbar color"
                onChange={onChangeColor}
                value={color}
              />
              <div className="controls full">
                <p>Disable colorful text animation:</p>
                <input
                  type="checkbox"
                  onChange={onChangeDisableAnimation}
                  value={disableAnimation}
                  defaultChecked={disableAnimation}
                />
              </div>
              <h2>Administration</h2>
              <p>Changing this value will transfer the moderator role from you to the recipient. You will not be able to return to this Settings page after saving.</p>
              <input
                placeholder="admin"
                onChange={onChangeAdmin}
                value={admin}
              />
              <h2>Weights</h2>
              <p>Assign user-specific weights to promote/demote content. To ban a user, set the user's weight to 0. Banned users will not be able to post or edit topics and comments. See the <a href="/faq">FAQ</a> for more details.</p>
              <ul>
                {Object.keys(weights).map(user => (
                  <li className="controls full">
                    <span>{user}</span>
                    <input
                      type="number"
                      onChange={onChangeWeight(user)}
                      value={weights[user]}
                    />
                  </li>
                ))}
              </ul>
              <button
                disabled={isEditing}
                onClick={onClickUpdate}
              >
                Update
              </button>
            </section>
          </div>}
        </section>
      </div>
    </main>
  </>
};

export default Settings;
