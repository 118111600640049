import { useEffect, useState } from 'react';

const useComments = (forum = 'news') => {
  const { API_URL } = window;

  const [comments, setComments] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchComments = async () => {
    const response = await fetch(`${API_URL}/items`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        z: forum
      })
    });

    setIsLoading(false);

    if (response?.ok) {
      const { items } = await response.json();

      const itemData = Object
        .values(items)
        .filter(({ parentId }) => parentId != null);

      for (const item of itemData) {
        const upvotes = item.upvotes.reduce((a, b) => a + b, 0);
        const downvotes = item.downvotes.reduce((a, b) => a + b, 0);

        item.reputation = upvotes - downvotes;
        item.points = Math.max(-1, item.reputation);

        if (item.points < 0) {
          const itemIndex = itemData.findIndex(({ id }) => id === item.id);

          itemData.splice(itemIndex, 1);
        }
      }

      setComments(
        Object.assign({},
          ...itemData.map(item => ({
            [item.id]: item
          }))
        )
      );
    }
  };

  useEffect(() => {
    fetchComments();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    comments,
    refetch: fetchComments
  };
};

export default useComments;
