import { useEffect, useState } from 'react';

import ContrastColor from 'contrast-color';

import Logo from '../Logo';

const ONLINE = 'online';
const OFFLINE_ERROR = 'Servers are down.';
const FEW_USERS = 'Ghost town!';
const LOW_USERS = "It's pretty slow right now";
const HIGH_USERS = "It's active right now";

const THEMES = [
  'X',
  'Y',
  'Z'
];

const Navigation = ({
  subject,
  forum,
  backgroundColor,
  imageURL,
  title
}) => {
  const { API_URL } = window;

  const [onlineCount, setOnlineCount] = useState(0);
  const [themeIndex, setThemeIndex] = useState(2);
  const [isLoading, setIsLoading] = useState(true);

  const [authLink, setAuthLink] = useState(
    <a className="auth-button" href={`/z/${forum}/login`}>Login</a>
  );

  useEffect(() => {
    const configData = localStorage.getItem('ZDIFF_CONFIG');

    if (configData) {
      const { themeName } = JSON.parse(configData);

      if (themeName) {
        setThemeIndex(THEMES.indexOf(themeName));
      }
    }

    const sessionData = localStorage.getItem('ZDIFF_SESSION');

    if (sessionData) {
      const {
        token,
        user = {}
      } = JSON.parse(sessionData);

      if (token) {
        setAuthLink(
          <a className="auth-button" href={`/z/${forum}/user?id=${user.id}`}>
            {user.id} ({user.reputation})
          </a>
        );
      }
    }

    const fetchStatus = async () => {
      const response = await fetch(`${API_URL}/ping`);

      setIsLoading(false);

      if (response?.ok) {
        const {
          onlineUsers,
          serverStatus
        } = await response.json();

        if (serverStatus !== ONLINE) {
          return alert(OFFLINE_ERROR);
        }

        setOnlineCount(onlineUsers);
      }
    };

    fetchStatus();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forum]);

  const onClickTheme = () => {
    let updatedThemeIndex = themeIndex;

    if (themeIndex === 2) {
      updatedThemeIndex = 0;
    } else {
      updatedThemeIndex++;
    }

    const themeName = THEMES[updatedThemeIndex];

    setThemeIndex(updatedThemeIndex);
    document.body.setAttribute('class', `theme-${themeName.toLowerCase()}`);

    localStorage.setItem(
      'ZDIFF_CONFIG',
      JSON.stringify({
        themeName
      })
    );
  };

  const isCold = onlineCount < 100;
  const isGhost = onlineCount < 10;
  const themeName = THEMES[themeIndex];

  const color = new ContrastColor({
    bgColor: backgroundColor
  }).contrastColor();

  return <>
    <header className="breadcrumbs" style={{ backgroundColor, color }}>
      <a href={`/z/${forum}`}>
        <Logo
          title={title}
          src={imageURL}
          backgroundColor={backgroundColor}
        />
      </a>
      {subject
        ? <>&nbsp;/&nbsp;<span className="subheading">{subject}</span></>
        : <ul className="nav" role="navigation">
            <li className="theme" title={`Current theme: ${themeName}`}>
              <button onClick={onClickTheme}>
                &lt;{themeName}&gt;
              </button>
            </li>
            <li>
              <a href={`/z/${forum}/newest`}>New</a>
            </li>
            <li>
              <a href={`/z/${forum}/newcomments`}>Comments</a>
            </li>
            <li>
              <a className="link" href={`/z/${forum}/submit`}>Submit</a>
            </li>
            {!isLoading && <li>
              <p>
                <span className="pulse" title={isCold ? isGhost ? FEW_USERS : LOW_USERS : HIGH_USERS}>
                  {(isCold
                    ? isGhost
                      ? '👻'
                      : '🧊'
                    : '🔥'
                  )}
                </span>
                <strong className="small">{onlineCount} online</strong>
              </p>
            </li>}
          </ul>}
      {authLink}
    </header>
  </>;
};

export default Navigation;
