import './styles.css';

const Card = ({
  name,
  z,
  description,
  color,
  imageURL,
  ago = () => {},
  updatedAt
}) => (
  <li key={name} className="card">
    <a href={`/z/${z}`} className="card-title">
      <h2 className="truncate">
        z/{z}
      </h2>
    </a>
    <div className="card-content">
      <div className="card-preview-img" style={{ backgroundColor: color }}>
        <img
          width={256}
          height={256}
          src={imageURL}
          alt="avatar"
        />
      </div>
      <div className="card-details">
        <p className="truncate">
          {description}
        </p>
        <em>
          {ago(new Date(updatedAt))}
        </em>
      </div>
    </div>
  </li>
);

export default Card;
