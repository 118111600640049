import Navigation from '../../components/Navigation';

import useToken from '../../hooks/useToken';

const Pricing = () => {
  const { isLoading, token, user } = useToken({
    redirect: false
  });

  return <>
    <Navigation
      forum="news"
      title="Pricing"
    />
    <main className="landing">
      <div className="page">
        <h2>Upgrade to Pro for more posts and moderator tools.</h2>
        {!isLoading && <p>To upgrade, click <a href={token ? `/z/news/user?id=${user.id}` : '/login'}>"upgrade"</a> on your profile.</p>}
        <section className="pricing">
          <div>
            <h2>Free</h2>
            <ul>
              <li><strong>Free forever!</strong></li>
              <li>Unlimited posts and replies</li>
              <li>Retain up to 1000 of the most recent posts</li>
              <li>Choose a forum name, logo, and description</li>
              <li>Customize forum colors and options</li>
            </ul>
          </div>
          <div>
            <h2>Pro</h2>
            <ul>
              <li>$10/month</li>
              <li>Unlimited posts and replies</li>
              <li>Retain up to 1 million of the most recent posts</li>
              <li>Choose a forum name, logo, and description</li>
              <li>Customize forum colors and options</li>
              <li>Appoint moderators</li>
              <li>Ban/demote/promote user content</li>
              <li>Access the REST API using the zDiff Web SDK (rate limits apply) <span className="dagger">†</span></li>
              <li>Use the zDiff Web SDK to build/host your own front-end <span className="dagger">†</span></li>
            </ul>
          </div>
        </section>
        <div>
          <a className="cta-button" href={token ? `/z/news/user?id=${user.id}` : '/login'}>
            Go to Profile
          </a>
        </div>
        <p><span className="dagger">†</span> <span>Coming soon to an npm near you</span></p>
      </div>
    </main>
  </>;
};

export default Pricing;
