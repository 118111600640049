import Linkify from 'react-linkify';

import Vote from '../../components/Vote';

const NestedComment = ({
  id,
  authorId,
  authorIp,
  authorRegion,
  authorCountry,
  authorFlag,
  userId,
  forum,
  points,
  text,
  createdAt,
  updatedAt,
  ago,
  disableReplies = false,
  isPosting,
  onClickPostComment,
  onClickEditComment
}) => (
  <>
    <span className="author">
      <Vote itemId={id} value={points} />
      <h6>Commented by <a href={`/z/${forum}/user?id=${authorId}`}>{authorId}</a></h6>
      <span title={`${authorRegion}, ${authorCountry}`}>
        ({authorFlag || '🏴‍☠️'}&nbsp;{authorIp})
      </span>
      <em>{ago(new Date(createdAt))}</em>
      {updatedAt && <em>(edited)</em>}
    </span>
    <p>
      {points > -1
        ? <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {text.match('<br />')
            ? <span dangerouslySetInnerHTML={{ __html: text }} />
            : text}
        </Linkify>
        : <span dangerouslySetInnerHTML={{ __html: text }} />}
    </p>
    <div className="controls">
      {authorId === userId && <button
        disabled={isPosting}
        onClick={onClickEditComment(id)}
        className="control"
      >
        Edit
      </button>}
      {!disableReplies && <button
        disabled={isPosting}
        onClick={onClickPostComment(id)}
        className="control"
      >
        Reply
      </button>}
    </div>
  </>
);

export default NestedComment;
