import useToken from '../../hooks/useToken';

import './styles.css';

const Header = () => {
  const { user } = useToken({
    redirect: false
  });

  const userId = user?.id;

  return <header className="header">
    <a href="/" className="branding">zDifferentiator</a>
    <nav>
      <a target="_blank" rel="noreferrer" href="https://news.zdifferentiator.com">News</a>
      <a href="/z">zSlash</a>
      <a href="/faq">FAQ</a>
    </nav>
    {!userId && <div className="login-cta-link">
      <a href="/login">Login</a>
    </div>}
    {user && <p className="user">Logged in as <a href={`/z/news/user?id=${user.id}`}>{userId}</a></p>}
  </header>;
};

export default Header;
