const Logo = ({ title, src, backgroundColor }) => <>
  <img
    alt="zDifferentiator"
    src={src || "/img/z.svg"}
    height="100%"
    style={{ backgroundColor }}
  />
  <strong>{title}</strong>
</>;

export default Logo;
