import { useState } from 'react';

import Navigation from '../../components/Navigation';

import useToken from '../../hooks/useToken';
import useForums from '../../hooks/useForums';

const Submit = ({ forum }) => {
  const { API_URL } = window;

  const [topic, setTopic] = useState('');
  const [text, setText] = useState('');
  const [isPosting, setIsPosting] = useState(false);
  const [isHackerNewsBanned, setIsHackerNewsBanned] = useState(false);
  const [isRedditBanned, setIsRedditBanned] = useState(false);
  const [isTwitterBanned, setIsTwitterBanned] = useState(false);

  const { token } = useToken();
  const { forums } = useForums();

  const currentForum = Object.values(forums).find(({ z }) => z === forum);

  const {
    color: backgroundColor,
    name: title,
    imageURL
  } = currentForum || {};

  const onClickPost = async () => {
    const response = await fetch(`${API_URL}/topic`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token,
        topic,
        text,
        z: forum,
        isHackerNewsBanned,
        isRedditBanned,
        isTwitterBanned
      })
    });

    if (response?.ok) {
      const { success } = await response.json();

      setIsPosting(false);

      if (success) {
        window.location.href = '/';
      }
    } else {
      if (response.status === 401) {
        localStorage.clear();

        window.location.href = '/login';

        return;
      }

      setIsPosting(false);
    }
  };

  const onChangeTopic = ({ target: { value }}) => setTopic(value);

  const onChangeText = ({ target: { value }}) => setText(value);

  const onChangeIsHackerNewsBanned = ({ target: { checked }}) => setIsHackerNewsBanned(checked);

  const onChangeIsRedditBanned = ({ target: { checked }}) => setIsRedditBanned(checked);

  const onChangeIsTwitterBanned = ({ target: { checked }}) => setIsTwitterBanned(checked);

  return <>
    <Navigation
      subject="Submit a new article"
      forum={forum}
      backgroundColor={backgroundColor}
      imageURL={imageURL}
      title={title}
    />
    <div className="form page">
      <h4>Write a captivating headline:</h4>
      <input
        required
        disabled={isPosting}
        placeholder="Write a topic headline (up to 900 characters)"
        onChange={onChangeTopic}
        value={topic}
      />
      <h4>Describe the content (optionally include URLs). The first URL detected in this text will become the link destination of the headline:</h4>
      <textarea
        required
        disabled={isPosting}
        placeholder="Write some supporting text (up to 4,000 characters)"
        onChange={onChangeText}
        checked={text.substring(0, 4000)}
      />
      <h4>Censorship history</h4>
      <div className="controls full">
        <h4>This information was removed from Reddit:</h4>
        <input
          type="checkbox"
          disabled={isPosting}
          onChange={onChangeIsRedditBanned}
          value={isRedditBanned}
          defaultChecked={isRedditBanned}
        />
      </div>
      <div className="controls full">
        <h4>This information was removed from Hacker News:</h4>
        <input
          type="checkbox"
          disabled={isPosting}
          onChange={onChangeIsHackerNewsBanned}
          value={isHackerNewsBanned}
          defaultChecked={isHackerNewsBanned}
        />
      </div>
      <div className="controls full">
        <h4>This information was removed from Twitter:</h4>
        <input
          type="checkbox"
          disabled={isPosting}
          onChange={onChangeIsTwitterBanned}
          value={isTwitterBanned}
          defaultChecked={isTwitterBanned}
        />
      </div>
      <button disabled={isPosting} onClick={onClickPost}>
        Post
      </button>
    </div>
  </>;
};

export default Submit;
