import { useEffect, useState } from 'react';

const getPenaltyByDate = itemDate => {
  const today = new Date();
  const date = new Date(itemDate);
  const timeDifference = today.getTime() - date.getTime();

  const penalty = (
    Math.floor(timeDifference / (1000 * 60 * 60 * 24))
  );

  return penalty;
};

const useTopics = (forum = 'news') => {
  const { API_URL } = window;

  const [topics, setTopics] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchTopics = async () => {
    const response = await fetch(`${API_URL}/items`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        z: forum
      })
    });

    setIsLoading(false);

    if (response?.ok) {
      const { items } = await response.json()

      const itemData = Object.values(items);

      for (const item of itemData) {
        if (item.parentId && items[item.parentId]) {
          if (!items[item.parentId].comments) {
            items[item.parentId].comments = {};
          }

          items[item.parentId].comments[item.id] = item;
        }

        const upvotes = item.upvotes.reduce((a, b) => a + b, 0);
        const downvotes = item.downvotes.reduce((a, b) => a + b, 0);
        const penalty = getPenaltyByDate(item.createdAt);

        item.reputation = upvotes - downvotes - penalty;
        item.points = Math.max(-1, upvotes - downvotes);
      }

      for (const item of itemData) {
        if (item.parentId) {
          delete items[item.id];
        }
      }

      setTopics(items);
    }
  };

  useEffect(() => {
    fetchTopics();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    topics,
    refetch: fetchTopics
  };
};

export default useTopics;
