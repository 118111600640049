import { useEffect, useState } from 'react';

import CardList from '../../components/CardList';
import Navigation from '../../components/Navigation';
import useForums from '../../hooks/useForums';

const Profile = ({ id, forum }) => {
  const { API_URL } = window;

  const [profile, setProfile] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState();

  const { forums, isLoading: isForumsLoading } = useForums();

  const currentForum = Object.values(forums).find(({ z }) => z === forum);

  const {
    color: backgroundColor,
    name: title,
    imageURL
  } = currentForum || {};

  useEffect(() => {
    const sessionData = localStorage.getItem('ZDIFF_SESSION');

    if (sessionData) {
      const {
        user = {}
      } = JSON.parse(sessionData);

      if (user?.id) {
        setUserId(user.id);
      }
    }

    const fetchProfile = async () => {
      const response = await fetch(`${API_URL}/profile`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id
        })
      });

      setIsLoading(false);

      if (response?.ok) {
        const {
          success,
          profile: updatedProfile
        } = await response.json();

        if (success && updatedProfile) {
          setProfile(updatedProfile);

          const title = updatedProfile?.id;

          document.title = title ? `${title} | ${forum}` : forum;
        }
      }
    };

    fetchProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const profileId = profile?.id;
  const reputation = profile?.reputation << 0;
  const isProfileUser = profileId && profileId === userId;

  const rep = <>
    {profile?.id || '-'}&nbsp;
    <span>({reputation})</span>
  </>;

  const userForums = (
    Object.values(forums).filter(({ admin }) => admin === id)
  );

  return !isLoading && <>
    <Navigation
      subject={rep}
      forum={forum}
      backgroundColor={backgroundColor}
      imageURL={imageURL}
      title={title}
    />
    <article>
      <div className="profile">
        <h2>{profileId || 'User data unavailable.'}</h2>
        <p>{profile.description}</p>
        {profileId && <ul>
          <li>
            Reputation: {reputation}
          </li>
          <li>
            Submissions: {profile.submissions << 0}
          </li>
          <li>
            Comments: {profile.comments << 0}
          </li>
        </ul>}
        {isProfileUser && <div className="controls">
          <button onClick={onClickLogout}>
            Logout
          </button>
          <button disabled title="Coming soon! For now, enjoy Pro for free.">
            Upgrade
          </button>
        </div>}
        {!isForumsLoading && <section>
          {userForums.length === 0
            ? <em style={{ color: '#64748b' }}>No data.</em>
            : <CardList
                title={`${profile?.id || 'Anonymous'} moderates the following communities:`}
                items={userForums}
              />}
        </section>}
      </div>
    </article>
  </>;
};

export default Profile;
