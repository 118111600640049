import { useState } from 'react';

import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import useToken from '../../hooks/useToken';
import useTopics from '../../hooks/useTopics';
import useForums from '../../hooks/useForums';

const Topics = ({ ago, sortBy, forum }) => {
  const { API_URL } = window;

  const [page, setPage] = useState(0);

  const { token } = useToken({ redirect: false });
  const { isLoading, topics } = useTopics(forum);
  const { forums } = useForums();

  const currentForum = Object.values(forums).find(({ z }) => z === forum);

  const {
    color: backgroundColor,
    name: title,
    imageURL,
    animationDisabled
  } = currentForum || {};

  const onClickUpvote = id => async () => {
    const response = await fetch(`${API_URL}/upvote`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token,
        itemId: id
      })
    });

    if (response?.ok) {
      const { success } = await response.json();

      if (success) {
        window.location.reload();
      }
    }
  };

  const onClickMore = () => {
    setPage(Math.min(9999, page + 1));
  };

  const byReputation = (a, b) => a.reputation > b.reputation ? -1 : 1;

  const byDate = (a, b) => new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1;

  const topicData = Object
    .values(topics)
    .sort(sortBy === 'date' ? byDate : byReputation)
    .slice(0, (page * 12) + 12);

  return !isLoading && <>
    <Navigation
      forum={forum}
      backgroundColor={backgroundColor}
      imageURL={imageURL}
      title={title}
    />
    {topicData.length === 0 && <em style={{ opacity: .5 }}>No items.</em>}
    <ol className="feed">
      {topicData.map(({
        id,
        topic: topicText,
        text,
        url: topicUrl,
        comments = {},
        points,
        createdAt
      }) => {
        const commentLength = Object.values(comments)?.length << 0;
        const commentsUrl = `/z/${forum}/item?id=${id}`;

        return (
          <li className={`topic${animationDisabled ? ' animation-disabled' : ''}`} key={id}>
            <span>
              <span className="vote">
                <span className="upvote" onClick={onClickUpvote(id)}>▲</span>
              </span>
              <span className="author">
                <a
                  href={topicUrl || commentsUrl}
                  target={topicUrl ? '_blank' : '_self'}
                  rel="noreferrer"
                  className="item"
                >
                  {topicText.substring(0, 100)}
                  {topicText.length > 100 ? '...' : ''}
                </a>
                <span className="info">
                  <span>&nbsp;&ndash;&nbsp;</span>
                  <a href={commentsUrl}>
                    {commentLength}&nbsp;comment{commentLength === 1 ? '' : 's'}
                  </a>
                  <span>&nbsp;&ndash;</span>&nbsp;
                  <em>
                    {ago(new Date(createdAt))}
                  </em>
                </span>
              </span>
            </span>
            <span className="small">
              <em>{points} {`point${Math.abs(points) !== 1 ? 's' : ''}`}</em>
            </span>
            <p className="message">
              {text}
            </p>
          </li>
        );
      })}
    </ol>
    {Object.values(topics).length > topicData.length && <button onClick={onClickMore}>More</button>}
    <Footer />
  </>;
};

export default Topics;
