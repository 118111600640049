import { useEffect, useState } from 'react';
import Linkify from 'react-linkify';

import Vote from '../../components/Vote';
import Navigation from '../../components/Navigation';
import NestedComment from '../../components/NestedComment';

import useToken from '../../hooks/useToken';
import useTopics from '../../hooks/useTopics';
import useForums from '../../hooks/useForums';

const PAGE_TITLE = 'Slacker News';

const Topic = ({ id, ago, forum }) => {
  const { API_URL } = window;

  const [topic, setTopic] = useState('');
  const [comment, setComment] = useState('');
  const [isPosting, setIsPosting] = useState(false);
  const [overlayClassName, setOverlayClassName] = useState('');
  const [timeoutId, setTimeoutId] = useState();
  const [targetItemId, setTargetItemId] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const { isLoading, topics } = useTopics(forum);
  const { token, user } = useToken({ redirect: false });
  const { forums } = useForums();

  const currentForum = Object.values(forums).find(({ z }) => z === forum);

  const {
    color: backgroundColor,
    animationDisabled,
    name: title,
    imageURL
  } = currentForum || {};

  useEffect(() => {
    if (isLoading) return;

    const updatedTopic = topics[id];

    setTopic(updatedTopic);

    const title = updatedTopic?.topic;

    document.title = title ? `${title} | ${PAGE_TITLE}` : PAGE_TITLE;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topics, id]);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, [timeoutId]);

  const openOverlay = () => {
    setIsPosting(true);
    setOverlayClassName('');

    requestAnimationFrame(() => {
      setOverlayClassName('show');
    });
  };

  const closeOverlay = () => {
    setComment('');
    setOverlayClassName('');
    setTargetItemId();

    setTimeoutId(
      setTimeout(() => {
        setIsPosting(false);
      }, 1000)
    );
  };

  const onClickOverlay = ({ target: { id }}) => {
    if (id === 'overlay') {
      closeOverlay();
    }
  };

  const onClickPostComment = id => () => {
    setIsEdit(false);
    setTargetItemId(id);
    openOverlay();
  };

  const onClickEditComment = id => () => {
    setIsEdit(true);

    setComment(id === topic.id
      ? topic.text
      : topic.comments[id]?.text
    );

    setTargetItemId(id);
    openOverlay();
  };

  const onClickPost = async () => {
    const response = await fetch(`${API_URL}/comment`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token,
        itemId: targetItemId,
        text: comment,
        z: forum
      })
    });

    setTargetItemId();

    if (response?.ok) {
      const { success } = await response.json();

      if (success) {
        window.location.reload();
      }
    } else {
      if (response.status === 401) {
        localStorage.clear();

        window.location.href = '/login';

        return;
      }
    }
  };

  const onClickSave = async () => {
    const response = await fetch(`${API_URL}/edit`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token,
        itemId: targetItemId,
        text: comment,
        z: forum
      })
    });

    setTargetItemId();

    if (response?.ok) {
      const { success } = await response.json();

      if (success) {
        window.location.reload();
      }
    } else {
      if (response.status === 401) {
        localStorage.clear();

        window.location.href = '/login';

        return;
      }
    }
  };

  const onChangeComment = ({ target: { value }}) => setComment(value);

  return <>
    <Navigation
      disabled={isPosting}
      subject={topic?.topic}
      forum={forum}
      backgroundColor={backgroundColor}
      imageURL={imageURL}
      title={title}
    />
    {isPosting && <aside id="overlay" className={overlayClassName} onClick={onClickOverlay}>
      <div className="form">
        <h2>{isEdit ? 'Edit' : 'Post'} Comment</h2>
        <textarea
          autoFocus
          required
          disabled={!isPosting}
          placeholder="Write a comment (up to 8,000 characters)"
          onChange={onChangeComment}
          value={comment}
        />
        <button disabled={!isPosting} onClick={isEdit ? onClickSave : onClickPost}>
          {isEdit ? 'Save Changes' : 'Post'}
        </button>
      </div>
    </aside>}
    <article>
      {topic && <div className={`topic ${animationDisabled ? 'animation-disabled' : ''} ${topic.points > 99 ? 'popular' : topic.points < 0 ? 'unpopular' : ''}`}>
        <h2>{topic.topic}</h2>
        <div className="author">
          <Vote itemId={topic.id} value={topic.points} />
          <h6>Posted by <a href={`/z/${forum}/user?id=${topic.authorId}`}>{topic.authorId}</a></h6>
          <span title={`${topic.authorRegion}, ${topic.authorCountry}`}>
            ({topic.authorFlag || '🏴‍☠️'}&nbsp;{topic.authorIp})
          </span>
          <em>{ago(new Date(topic.createdAt))}</em>
          {topic.updatedAt && <em>(edited)</em>}
        </div>
        <p>
          {topic.points > -1
            ? <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {topic.text.match('<br />')
                ? <span dangerouslySetInnerHTML={{ __html: topic.text }} />
                : topic.text}
            </Linkify>
            : <span dangerouslySetInnerHTML={{ __html: topic.text }} />}
        </p>
        <div className="controls">
          {topic?.authorId === user?.id && <button
            disabled={isPosting}
            onClick={onClickEditComment(topic.id)}
            className="control"
          >
            Edit
          </button>}
          {token && <button
            disabled={isPosting}
            onClick={onClickPostComment(topic.id)}
            className="control"
          >
            Reply
          </button>}
        </div>
        <ul>
          {Object.values(topic.comments || {}).sort((a, b) => b.reputation - a.reputation).map(({
            id,
            authorId,
            authorIp,
            authorRegion,
            authorCountry,
            authorFlag,
            text,
            points,
            comments = {},
            createdAt,
            updatedAt
          }) => (
            <li className={`comment${animationDisabled ? ' animation-disabled' : ''}`} key={id}>
              <span className={points > 99 ? 'popular' : points < 0 ? 'unpopular' : ''}>
                <NestedComment {...{
                  id,
                  authorId,
                  authorIp,
                  authorRegion,
                  authorCountry,
                  authorFlag,
                  forum,
                  points,
                  text,
                  createdAt,
                  updatedAt,
                  ago,
                  token,
                  userId: user?.id,
                  isPosting,
                  onClickPostComment,
                  onClickEditComment,
                  disableReplies: !token
                }} />
                <ul>
                  {Object.values(comments).sort((a, b) => b.reputation - a.reputation).map(({
                    id,
                    authorId,
                    authorIp,
                    authorRegion,
                    authorCountry,
                    authorFlag,
                    text,
                    points,
                    comments = {},
                    createdAt,
                    updatedAt
                  }) => (
                    <li className={`comment nested${animationDisabled ? ' animation-disabled' : ''}`} key={id}>
                      <span className={points > 99 ? 'popular' : points < 0 ? 'unpopular' : ''}>
                        <NestedComment {...{
                          id,
                          authorId,
                          authorIp,
                          authorRegion,
                          authorCountry,
                          authorFlag,
                          forum,
                          points,
                          text,
                          createdAt,
                          updatedAt,
                          ago,
                          token,
                          userId: user?.id,
                          isPosting,
                          onClickPostComment,
                          onClickEditComment,
                          disableReplies: !token
                        }} />
                        <ul>
                          {Object.values(comments).sort((a, b) => b.reputation - a.reputation).map(({
                            id,
                            authorId,
                            authorIp,
                            authorRegion,
                            authorCountry,
                            authorFlag,
                            text,
                            points,
                            comments = {},
                            createdAt,
                            updatedAt
                          }) => (
                            <li className={`comment nested${animationDisabled ? ' animation-disabled' : ''}`} key={id}>
                              <span className={points > 99 ? 'popular' : points < 0 ? 'unpopular' : ''}>
                                <NestedComment {...{
                                  id,
                                  authorId,
                                  authorIp,
                                  authorRegion,
                                  authorCountry,
                                  authorFlag,
                                  forum,
                                  points,
                                  text,
                                  createdAt,
                                  updatedAt,
                                  ago,
                                  token,
                                  userId: user?.id,
                                  isPosting,
                                  onClickPostComment,
                                  onClickEditComment,
                                  disableReplies: !token
                                }} />
                                <ul>
                                  {Object.values(comments).sort((a, b) => b.reputation - a.reputation).map(({
                                    id,
                                    authorId,
                                    authorIp,
                                    authorRegion,
                                    authorCountry,
                                    authorFlag,
                                    text,
                                    points,
                                    comments = {},
                                    createdAt,
                                    updatedAt
                                  }) => (
                                    <li className={`comment nested${animationDisabled ? ' animation-disabled' : ''}`} key={id}>
                                      <span className={points > 99 ? 'popular' : points < 0 ? 'unpopular' : ''}>
                                        <NestedComment {...{
                                          id,
                                          authorId,
                                          authorIp,
                                          authorRegion,
                                          authorCountry,
                                          authorFlag,
                                          forum,
                                          points,
                                          text,
                                          createdAt,
                                          updatedAt,
                                          ago,
                                          token,
                                          userId: user?.id,
                                          isPosting,
                                          onClickPostComment,
                                          onClickEditComment,
                                          disableReplies: !token
                                        }} />
                                        <ul>
                                          {Object.values(comments).sort((a, b) => b.reputation - a.reputation).map(({
                                            id,
                                            authorId,
                                            authorIp,
                                            authorRegion,
                                            authorCountry,
                                            authorFlag,
                                            text,
                                            points,
                                            createdAt,
                                            updatedAt
                                          }) => (
                                            <li className={`comment nested${animationDisabled ? ' animation-disabled' : ''}`} key={id}>
                                              <NestedComment {...{
                                                id,
                                                authorId,
                                                authorIp,
                                                authorRegion,
                                                authorCountry,
                                                authorFlag,
                                                forum,
                                                points,
                                                text,
                                                createdAt,
                                                updatedAt,
                                                ago,
                                                token,
                                                userId: user?.id,
                                                isPosting,
                                                onClickPostComment,
                                                onClickEditComment,
                                                disableReplies: true
                                              }} />
                                            </li>
                                          ))}
                                        </ul>
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </span>
                    </li>
                  ))}
                </ul>
              </span>
            </li>
          ))}
        </ul>
      </div>}
    </article>
  </>;
};

export default Topic;
