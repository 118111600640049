import useToken from "../../hooks/useToken";

const UP = 'up';
const DOWN = 'down';

const Vote = ({ itemId, value }) => {
  const { API_URL } = window;

  const { token } = useToken({ redirect: false });

  const vote = async (type = UP) => {
    const response = await fetch(`${API_URL}/${type}vote`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token,
        itemId
      })
    });

    if (response?.ok) {
      const {
        success,
        message
      } = await response.json();

      if (message) {
        alert(message);
      }

      if (success) {
        window.location.reload();
      }
    }
  };

  const onClickUpvote = () => vote(UP);

  const onClickDownvote = () => vote(DOWN);

  return (
    <span className="vote" id={`vote-${itemId}`}>
      <span className="upvote" onClick={onClickUpvote}>▲</span>
      <span>{value}</span>
      <span className="downvote" onClick={onClickDownvote}>▼</span>
    </span>
  );
};

export default Vote;
