import { useEffect, useState } from 'react';

const useForums = () => {
  const { API_URL } = window;

  const [forums, setForums] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchForums = async () => {
    const response = await fetch(`${API_URL}/forums`);

    setIsLoading(false);

    if (response?.ok) {
      const { forums } = await response.json();

      setForums(
        Object
          .values(forums)
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      );
    }
  };

  useEffect(() => {
    fetchForums();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    forums,
    refetch: fetchForums
  };
};

export default useForums;
